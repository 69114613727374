import React from 'react';
import ReactTooltip from 'react-tooltip';

import { PageHeading } from 'components/Typography';
import Layout from 'layouts';
import { SEO } from 'SEO';

import { Addresses } from './components/Addresses';
import { ContactInfo } from './components/ContactInfo';
import { Map } from './components/Map';
import { ContactPageElements } from './elements';

const { Container, Content } = ContactPageElements;

export const ContactPage: React.FC = () => (
  <Layout>
    <SEO title="Kontakt" canonicalHref="/kontakt/" />
    <Container>
      <Content>
        <PageHeading>Kontakt</PageHeading>
        <ContactInfo />
      </Content>
      <Addresses marker="kielczowska" onChange={(_newMarker: string) => null} />
      <Map marker="kielczowska" />
    </Container>
    <ReactTooltip effect="solid" place="bottom" />
  </Layout>
);
