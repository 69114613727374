import React from 'react';

import { useConfig } from 'lib/hooks';

import { AddressesElements } from './elements';

const { Container, Address, City, Street } = AddressesElements;

const DestinationCheckIcon: React.FC<{ url: string }> = ({ url }) => (
  <a href={url} target="_blank" rel="noreferrer" data-tip="Sprawdz dojazd" onClick={(e) => e.stopPropagation()}>
    Dojazd
  </a>
);

export const Addresses: React.FC<{ marker: string; onChange: (marker: string) => void }> = ({ marker, onChange }) => {
  const config = useConfig();

  return (
    <Container depth={5} round={8}>
      <Address isActive={marker === 'kielczowska'} onClick={() => onChange('kielczowska')}>
        <City>
          Biuro i Hala Produkcyjna <DestinationCheckIcon url={config.routeLink.kielczowska} />
        </City>
        <Street>ul. Kiełczowska 68D, 51-315 Wrocław</Street>
      </Address>
    </Container>
  );
};
