import styled, { css } from 'styled-components';

import { Paper } from 'components/Paper';

const Container = styled(Paper)(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET')} {
      align-self: flex-end;
      display: flex;
      grid-area: map;
      height: 90px;
      justify-content: center;
      margin: ${theme.spacing.huge};
      overflow: hidden;
      padding: 0;
      z-index: 2;
    }
  `
);

const Address = styled.address<{ isActive: boolean }>(
  ({ theme, isActive }) => css`
    background: ${isActive ? `${theme.color.gray[300]}80` : 'transparent'};
    border-bottom: 3px solid ${isActive ? theme.color.blue[400] : 'transparent'};
    height: 100%;
    padding: ${theme.spacing.big};
    width: 100%;

    a {
      color: ${theme.color.blue[300]};
    }

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      width: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  `
);

const City = styled.div(
  ({ theme }) => css`
    ${theme.font(16, 'BOLD')};
    align-items: center;
    color: ${theme.color.blue[200]};
    display: flex;
    justify-content: space-between;

    a {
      ${theme.font(13, 'SEMIBOLD')};
      color: ${theme.color.blue[400]};

      &:hover {
        text-decoration: underline;
      }
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      ${theme.font(18, 'BOLD')};
    }
  `
);

const Street = styled.div(
  ({ theme }) => css`
    color: ${theme.color.gray[200]};
    ${theme.font(14, 'BOLD')};
  `
);

export const AddressesElements = {
  Container,
  Address,
  City,
  Street
};
