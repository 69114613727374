import React from 'react';

import { Icon } from 'components/Icon';
import { HeadingDescription } from 'components/Typography';

import { ContactInfoElements } from './elements';

const { Container, Content, Heading, IconWrapper, Departments } = ContactInfoElements;

export const ContactInfo: React.FC = () => {
  return (
    <Container>
      <HeadingDescription>
        Nasze biuro jest czynne od poniedziałku do piątku w godzinach 7:00 - 15:00
      </HeadingDescription>
      <Departments>
        <Content>
          <IconWrapper>
            <Icon name="book-open" />
          </IconWrapper>
          <Heading>Biuro, Księgowość</Heading>
          <a href="tel:+48 71 345 65 44">+48 71 345 65 44 wew. 1</a>
          <a href="tel:+48 510 492 668">+48 510 492 668</a>
          <a href="tel:++48784530229">+48 784 530 229</a>
          <a href="mailto:biuro@bachem.pl">biuro@bachem.pl</a>
          <a href="mailto:ksiegowosc@bachem.pl">ksiegowosc@bachem.pl</a>
        </Content>
        <Content>
          <IconWrapper>
            <Icon name="person" />
          </IconWrapper>
          <Heading>Zapytania i technologie</Heading>
          <a href="tel:+48 71 345 65 44">+48 71 345 65 44 wew. 2</a>
          <a href="tel:+48 602 435 572">+48 602 435 572</a>
          <a href="mailto:biuro@bachem.pl">biuro@bachem.pl</a>
        </Content>
        <Content>
          <IconWrapper>
            <Icon name="edit-2" />
          </IconWrapper>
          <Heading>Zamówienia i terminy realizacji</Heading>
          <a href="tel:+48713456544">+48 71 345 65 44 wew. 3</a>
          <a href="tel:+48600806679">+48 600 806 679</a>
          <a href="mailto:biuro@bachem.pl">biuro@bachem.pl</a>
        </Content>
        <Content>
          <IconWrapper>
            <Icon name="edit-2" />
          </IconWrapper>
          <Heading>Projekty</Heading>
          <a href="tel:+48713456544">+48 71 345 65 44 wew. 3</a>
          <a href="tel:+48600806679">+48 600 806 679</a>
          <a href="mailto:projekty@bachem.pl">projekty@bachem.pl</a>
        </Content>
        <Content>
          <IconWrapper>
            <Icon name="layers" />
          </IconWrapper>
          <Heading>Przygotowanie produkcji</Heading>
          <a href="tel:+48713456544">+48 71 345 65 44 wew. 5</a>
          <a href="tel:+48500816348">+48 500 816 348</a>
          <a href="mailto:produkcja@bachem.pl">produkcja@bachem.pl</a>
        </Content>
        <Content>
          <IconWrapper>
            <Icon name="layers" />
          </IconWrapper>
          <Heading>Obróbka laserowa</Heading>
          <a href="tel:++48784530229">+48 784 530 229</a>
          <a href="mailto:lasery@bachem.pl">lasery@bachem.pl</a>
        </Content>
        <Content>
          <IconWrapper>
            <Icon name="globe" />
          </IconWrapper>
          <Heading>Administracja i Marketing</Heading>
          <a href="tel:+48713456544">+48 71 345 65 44 wew. 8</a>
          <a href="tel:++48509645442">+48 509 645 442</a>
          <a href="mailto:marketing@bachem.pl,">marketing@bachem.pl</a>
        </Content>
        <Content>
          <IconWrapper>
            <Icon name="done-all" />
          </IconWrapper>
          <Heading>Kontrola jakości i wysyłka zamówień</Heading>
          <a href="tel:+48713456544">+48 71 345 65 44 wew. 7</a>
          <a href="tel:++48509645442">+48 798 443 989</a>
          <a href="mailto:kj@bachem.pl,">kj@bachem.pl</a>
        </Content>
      </Departments>
    </Container>
  );
};
