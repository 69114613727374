import styled, { css } from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Departments = styled.section(
  ({ theme }) => css`
    display: grid;
    justify-content: center;
    margin-top: ${theme.spacing.huge};

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      grid-template-columns: 1fr 1fr;
      margin: ${theme.spacing.huge} 0;
    }
  `
);

const Content = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-column-gap: ${theme.spacing.big};
    grid-template-columns: 24px 1fr;
    height: fit-content;
    margin-bottom: ${theme.spacing.mid};
    > p,
    a {
      grid-column: 2;
    }

    a {
      color: ${theme.color.gray[200]};
      ${theme.font(14, 'SEMIBOLD')};

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    ${theme.onScreen('TABLET')} {
      grid-template-columns: 48px 1fr;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      margin-bottom: ${theme.spacing.big};
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      margin-bottom: ${theme.spacing.huge};
    }
  `
);

const IconWrapper = styled.div(
  ({ theme }) => css`
    grid-row: span 2;
    height: 24px;
    width: 24px;

    > svg {
      fill: ${theme.color.blue[400]};
      height: 100%;
      width: 100%;
    }

    ${theme.onScreen('TABLET')} {
      background: ${theme.color.gray[300]};
      border-radius: 100%;
      height: 48px;
      padding: 10px;
      width: 48px;
    }
  `
);

const Heading = styled.h2(
  ({ theme }) => css`
    color: ${theme.color.gray[100]};
    grid-column: 2;
    grid-row: 1;
    ${theme.font(14, 'BOLD')};

    ${theme.onScreen('TABLET')} {
      ${theme.font(18, 'BOLD')};
    }
  `
);

export const ContactInfoElements = { Container, Content, Heading, IconWrapper, Departments };
