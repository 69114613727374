import styled, { css } from 'styled-components';

const Container = styled.main(
  ({ theme }) => css`
    display: grid;
    grid-template-areas:
      'content'
      'map';
    padding: ${theme.spacing.big};

    ${theme.onScreen('SMALL_DESKTOP')} {
      grid-template-areas: 'map content';
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      min-height: calc(100vh - ${theme.size.menuHeight} - ${theme.size.subMenuHeight});
    }
  `
);

const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    grid-area: content;

    ${theme.onScreen('SMALL_DESKTOP')} {
      padding: 0 ${theme.spacing.huge};
    }
  `
);

export const ContactPageElements = {
  Container,
  Content
};
