import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import 'mapbox-gl/src/css/mapbox-gl.css';

import { Icon } from 'components/Icon';
import { useConfig } from 'lib/hooks';

const Wrapper = styled.div(
  ({ theme }) => css`
    display: none;
    grid-area: map;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      display: block;
      width: 100%;
      z-index: 1;
      height: 50vh;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      height: 100%;
    }
  `
);

const LocationMarker = styled(Icon)(
  ({ theme }) => css`
    fill: ${theme.color.gray[200]};
    height: 48px;
    transform: translateY(-50%);
    width: 48px;
  `
);

function useMap(markerName: string): [React.MutableRefObject<HTMLDivElement>, React.MutableRefObject<HTMLDivElement>] {
  const map = useRef(null);
  const markerRef = useRef(null);
  const config = useConfig();
  const theme = useTheme();

  const center = config.mapOptions.center[markerName];
  const marker = config.mapOptions.marker[markerName];

  useEffect(() => {
    if (markerRef.current !== null && map.current !== null) {
      const mapbox = new mapboxgl.Map({
        accessToken: process.env.GATSBY_MAP_TOKEN,
        container: map.current,
        ...config.mapOptions,
        center
      });

      const coordinateList = config.routePoints;
      mapbox.on('load', () => {
        if (markerName === 'kielczowska') {
          mapbox.addSource('route', {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: coordinateList
              }
            }
          });

          mapbox.addLayer({
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': theme.color.blue[400],
              'line-width': 7
            }
          });
        }
      });

      new mapboxgl.Marker({ element: markerRef.current }).setLngLat(marker).addTo(mapbox);
    }
  }, [config.mapOptions, config.routePoints, marker, center, theme.color.blue, markerName]);

  return [map, markerRef];
}

export const Map: React.FC<{ marker: string }> = ({ marker }) => {
  const [map, markerRef] = useMap(marker);

  return (
    <>
      <div ref={markerRef} data-tip="Tabliczki znamionowe Bachem">
        <LocationMarker name="pin" />
      </div>
      <Wrapper ref={map} />
    </>
  );
};
